import {create} from 'zustand';
import {persist} from 'zustand/middleware';
import {immer} from 'zustand/middleware/immer';
import {checkoutSlice, CheckoutSlice} from './checkout-slice';
import {DefaultSlice, defaultSlice} from './default-slice';
import {FeedSlice, feedSlice} from './feed-slice';

type CombinedState = DefaultSlice & FeedSlice & CheckoutSlice;

export const useStore = create<CombinedState>()(
  immer(
    persist(
      (...args) => ({
        ...checkoutSlice(...args),
        ...defaultSlice(...args),
        ...feedSlice(...args),
      }),
      {
        name: 'delorand',
        partialize: state => ({
          location: state.location,
        }),
        onRehydrateStorage: () => (state, error) => {
          if (error) {
            if (state) state.hydrated = false;
          } else if (state) state.hydrated = true;
        },
        // getStorage: () => storage as StateStorage,
      }
    )
  )
);
