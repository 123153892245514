import {CategoryGroup} from '@delorand/api/helpers/category';
import {FeedWithRank} from '@delorand/api/helpers/feed';
import {StateCreator} from 'zustand';
import {CheckoutSlice} from './checkout-slice';
import {FeedSlice} from './feed-slice';

export const defaultSlice: StateCreator<
  DefaultSlice & FeedSlice & CheckoutSlice,
  [
    ['zustand/immer', never],
    ['zustand/devtools', never],
    ['zustand/persist', unknown],
  ],
  [],
  DefaultSlice
> = set => ({
  cartPath: 'cart',
  setCartPath: path =>
    set(state => {
      state.cartPath = path;
    }),
  categories: [],
  setCategories: categories => set({categories}),
  initialFeed: null,
  setInitialFeed: initialFeed => set({initialFeed}),
  auth: {modalState: false},
  hydrated: false,
  searchOpen: false,
  openSearch: () =>
    set(state => {
      state.searchOpen = true;
    }),
  closeSearch: () =>
    set(state => {
      state.searchOpen = false;
    }),
  onAfterAuth: payload => {
    set(state => {
      state.afterAuth = payload;
    });
  },
  setAfterRouting: (key, fn) => {
    set(state => {
      state.afterRouting = {fn, key};
    });
  },
});

export interface DefaultSlice {
  initialFeed: FeedWithRank | null;
  hydrated: boolean;
  cartPath: string;
  setCartPath: (path: string) => void;
  searchOpen: boolean;
  openSearch: () => void;
  closeSearch: () => void;
  categories: CategoryGroup[];
  setCategories: (category: CategoryGroup[]) => void;
  setInitialFeed: (feed: FeedWithRank) => void;
  afterAuth?: () => void;
  onAfterAuth: (functionPayload: () => any) => void;
  afterRouting?: {key: 'ADD_ADDRESS'; fn: () => void};
  setAfterRouting: (key: 'ADD_ADDRESS', functionPayload: () => void) => void;
}
